import React, { useEffect, useState } from 'react'
import { collection, doc, getDoc, addDoc, onSnapshot, query, updateDoc, arrayUnion, arrayRemove, getDocs, orderBy, deleteDoc } from 'firebase/firestore';
import {db} from '../FirebaseInit';
import './Accounts.css';
import { Add, AddRounded, ArrowForwardIosRounded, ArrowForwardRounded, CheckRounded, CloseRounded, DateRange, DeleteForeverRounded, Edit, MoreVertRounded, RefreshRounded, SendRounded, SortByAlphaRounded } from '@mui/icons-material';
import axios from 'axios';
import { CircularProgress } from '@mui/material';




function Accounts({darkMode, serverURL, refreshFiles, setRefreshFiles}) {
    const [accounts, setAccounts] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState({});
    const [showCreateAccount, setShowCreateAccount] = useState(false);
    const [createAccountURL, setCreateAccountURL] = useState('');
    const [createAccountUsername, setCreateAccountUsername] = useState('');
    const [createAccountPassword, setCreateAccountPassword] = useState('');
    const [createAccountBrandID, setCreateAccountBrandID] = useState('1');
    const [selectedFile, setSelectedFile] = useState({});
    const [pushingToSendy, setPushingToSendy] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const [showRenamePopup, setShowRenamePopup] = useState(false);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [selectedFileForDelete, setSelectedFileForDelete] = useState({});
    const [renameText, setRenameText] = useState('');
    const [selectedFileForRename, setSelectedFileForRename] = useState({});
    const [showAssociateWithAccountPopup, setShowAssociateWithAccountPopup] = useState(false);
    const [selectedFileForAssociateWithAccount, setSelectedFileForAssociateWithAccount] = useState({});
    const [showPushToSendy, setShowPushToSendy] = useState(false);
    const [sendyListName, setSendyListName] = useState('');
    const [pushToSendyListInfo, setPushToSendyListInfo] = useState({});
    const [showSelectionPane, setShowSelectionPane] = useState(false);
    const [selectedAccountForAssociateWithAccount, setSelectedAccountForAssociateWithAccount] = useState({});
    const [files, setFiles] = useState([]);
    const [showEditAccount, setShowEditAccount] = useState(false);
    const [showDeleteAccountPopup, setShowDeleteAccountPopup] = useState(false);
    const [selectedPushToSendyPreset, setSelectedPushToSendyPreset] = useState(0);
    const [listRangeStart, setListRangeStart] = useState(0);
    const [listRangeEnd, setListRangeEnd] = useState(100000000000);
    const [selectedSuppFiles, setSelectedSuppFiles] = useState([]);
    const [selectedSuppFileNames, setSelectedSuppFileNames] = useState([]);
    const [updatingMetrics, setUpdatingMetrics] = useState(false);
    const [accountSearchValue, setAccountSearchValue] = useState('');
    const [filteredAccounts, setFilteredAccounts] = useState([]);
     


    async function getAccounts() {
        setAccounts([]);
    
        const q = query(collection(db, "accounts"), orderBy("createdAt", "desc"));
        
        try {
            // Fetch documents one time
            const querySnapshot = await getDocs(q);
            const accountsArray = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
    
    
            // Create a map for files
            const filesMap = new Map(files.map(file => [
                file.name,
                { attributes: file.attributes, size: file.size }
            ]));
    
            // Update accounts with attributes and size
            const updatedAccountsArray = accountsArray.map(account => {
                const updatedFiles = account?.files?.map(file => {
                    const fileData = filesMap.get(file.name) || {};
                    return {
                        ...file,
                        attributes: fileData.attributes || file.attributes,
                        size: fileData.size || file.size
                    };
                });
    
                return { ...account, files: updatedFiles };
            });
    
            console.log(updatedAccountsArray);
            setAccounts(updatedAccountsArray);
            setFilteredAccounts(updatedAccountsArray);
    
            // Reselect the account after accounts have been refreshed
            if (selectedAccount.id) {
                const refreshedAccount = updatedAccountsArray.find(account => account.id === selectedAccount.id);
                setSelectedAccount(refreshedAccount || {});
            }
    
        } catch (error) {
            console.error("Error fetching accounts:", error);
        }
        return
    }
    
    
    useEffect(() => {
        if (files.length > 0) {
            getAccounts();
        }
    }, [files]);

    

    async function refreshData() {
        await getFiles();
        if (files.length > 0) {
            getAccounts();
        }
        return
    }
    

 
 


    async function createAccount() {
        try {
            const docRef = await addDoc(collection(db, "accounts"), {
                name: extractUsername(createAccountURL),
                url: createAccountURL,
                username: createAccountUsername,
                password: createAccountPassword,
                brandID: createAccountBrandID,
                files: [],
                send_rate: 0,
                sent_today: 0,
                daily_quota: 0,
                last_sent: new Date,
                createdAt: new Date,
            });
            console.log("Document written with ID: ", docRef.id);
            setCreateAccountURL('');
            setCreateAccountUsername('');
            setCreateAccountPassword('');
            setCreateAccountBrandID('1');
            setShowCreateAccount(false);
            setRefreshFiles(!refreshFiles);
        } catch (e) {
            console.error("Error adding document: ", e);
        }
        return
    }



    async function editAccount() {
        try {
            const docRef = await updateDoc(doc(db, "accounts", selectedAccount.id), {
                name: extractUsername(createAccountURL),
                url: createAccountURL,
                username: createAccountUsername,
                password: createAccountPassword,
                brandID: createAccountBrandID,
            });
            setCreateAccountURL('');
            setCreateAccountUsername('');
            setCreateAccountPassword('');
            setCreateAccountBrandID('1');
            setShowEditAccount(false);
            setRefreshFiles(!refreshFiles);
        } catch (e) {
            console.error("Error adding document: ", e);
        }
        return
    }


    async function deleteAccount() {
        await deleteDoc(doc(db, "accounts", selectedAccount.id));
        setShowDeleteAccountPopup(false);
        setSelectedAccount({});
        return
    }




    async function getFiles() {
        try {
          const response = await axios.get(serverURL + '/getFiles');
          console.log('response:', response.data);
          setFiles(response.data);
        } catch (error) {
          console.error('ERROR getting files:', error);
        }
        return 
    }

    useEffect(() => {
        getFiles();
    }, [refreshFiles])




    function extractUsername(url) {
        // Define a regular expression to match the username part of the URL
        const regex = /https:\/\/(?:www\.)?([^\.]+)\.com/;
        
        // Use the regex to extract the username
        const match = url.match(regex);
        
        // If a match is found, return the first capturing group; otherwise, return null
        return match ? match[1] : null;
    }
    




    function formatFileSize(bytes) {
        if (bytes === 0) return '0 Bytes';
    
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(1024));
        const formattedSize = bytes / Math.pow(1024, i);
        
        // Check if formattedSize is a number
        if (isNaN(formattedSize)) return '';
    
        return `${formattedSize.toFixed(1)} ${sizes[i]}`;
    }



    const downloadFile = async (filename) => {
        try {
          const response = await axios.get(`${serverURL}/downloadFile?fileName=${filename}`, {
            responseType: 'blob', // Set responseType to 'blob' for binary data
          });
      
          // Create a URL for the blob
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename); // Specify the file name for the download
          document.body.appendChild(link);
          link.click();
          link.remove();
          window.URL.revokeObjectURL(url); // Clean up the URL object
        } catch (error) {
          console.error('Error downloading the file:', error);
        }
        return
    };



    async function deleteFile(file) {
        try {

            console.log(file.name)

            await disassociateWithAccount(file.name);

            const response = await axios.delete(`${serverURL}/deleteFile?fileName=${file.name}`);
            console.log('response:', response.data);
            setRefreshFiles(!refreshFiles);
            setShowDeletePopup(false);
        } catch (error) {
            console.error('ERROR deleting file:', error);
        }
        return
    }



    async function pushToSendy() {
        try {
            setPushingToSendy(true)
            const listInfo = {
                listRangeStart: Number(listRangeStart),
                listRangeEnd: Number(listRangeEnd),
                masterListLength: Number(pushToSendyListInfo?.attributes?.totalEmailCount) - pushToSendyListInfo?.alreadySent,
                masterList: pushToSendyListInfo.name,
                outputFileName: sendyListName,
                accountURL: selectedAccount.url,
                accountUsername: selectedAccount.username,
                accountPassword: selectedAccount.password,
                accountBrandID: selectedAccount.brandID,
                accountID: selectedAccount.id,

                suppressionFilePath: selectedSuppFiles.filter(file => file.attributes.fileType === "supp" && file.attributes.suppType === "email").map(file => file.name),
                domainSuppressionFilePath: selectedSuppFiles.filter(file => file.attributes.fileType === "supp" && file.attributes.suppType === "domain").map(file => file.name),
                masterListNumberOfEmails: selectedSuppFiles.filter(file => file.attributes.fileType === "master").map(file => file.attributes.totalEmailCount)[0]
            }

            console.log('listInfo:', listInfo);

            const response = await axios.post(`${serverURL}/prepareSendyList`, listInfo);
            console.log('response:', response.data);
            setRefreshFiles(!refreshFiles);
            setPushingToSendy(false);
            setShowPushToSendy(false);
            setSendyListName('');
            await refreshData();
        } catch (error) {
            console.error('ERROR pushing file:', error);
            setPushingToSendy(false);
            setShowPushToSendy(false);
            await refreshData();
        }
        return
    }





    async function renameFile() {
        try {
            const data = {
                oldName: selectedFileForRename.name,
                newName: renameText,
            }
            const response = await axios.post(`${serverURL}/renameFile`, data);
            console.log('response:', response.data);
            setRefreshFiles(!refreshFiles);
            setShowRenamePopup(false);
        } catch (error) {
            console.error('ERROR renaming file:', error);
        }
        return 
    }




    async function associateWithAccount() {
        const accountRef = doc(db, "accounts", selectedAccountForAssociateWithAccount.id);
    
        // Get the current document data
        const accountDoc = await getDoc(accountRef);
        if (!accountDoc.exists()) {
            console.error("No such document!");
            return;
        }
    
        const accountData = accountDoc.data();
        const files = accountData.files || [];
    
        // Check if a file with the same name already exists
        const fileExists = files.some(file => file.name === selectedFileForAssociateWithAccount.name);
    
        if (!fileExists) {
            // File does not exist, update the document
            await updateDoc(accountRef, {
                files: arrayUnion({
                    name: selectedFileForAssociateWithAccount.name,
                    created: new Date(),
                    modified: new Date(),
                    alreadySent: 0,
                    records: 0,
                })
            });
        } else {
            console.log("File already exists in the array.");
        }
        await refreshData();
        setShowAssociateWithAccountPopup(false);
        return 
    }



    async function disassociateWithAccount(fileName) {

        console.log(selectedAccount.id)
        const accountRef = doc(db, "accounts", selectedAccount.id);
    
        // Get the current document data
        const accountDoc = await getDoc(accountRef);
        if (!accountDoc.exists()) {
            console.error("No such document!");
            return;
        }
    
        const accountData = accountDoc.data();
        const files = accountData.files || [];
    
        // Filter out the file with the name to disassociate
        const updatedFiles = files.filter(file => file.name !== fileName);
    
        if (files.length === updatedFiles.length) {
            console.log("File not found in the array.");
            return;
        }
    
        // Update the document with the new list of files
        await updateDoc(accountRef, {
            files: updatedFiles
        });
        await refreshData();
        return 
    }




    function formatDate(date) {
        // Check if the input is a valid Date object
        if (!(date instanceof Date) || isNaN(date.getTime())) {
            throw new Error('Invalid date');
        }
    
        // Get the month (0-based, so add 1 and pad with leading zero if necessary)
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
    
        // Get the day of the month and pad with leading zero if necessary
        const day = date.getDate().toString().padStart(2, '0');
    
        // Return the formatted date
        return `${month}${day}`;
    }

    


    function adjustSelection(file) {
        // Convert selectedFileNames to a Set for faster lookup
        const selectedFileNamesSet = new Set(selectedSuppFileNames);
        
        let updatedFilesSelection;
        
        // Check if the file name is in selectedFileNames
        if (selectedFileNamesSet.has(file.name)) {
            // Remove file from selection if it is already selected
            updatedFilesSelection = selectedSuppFiles.filter(item => item.name !== file.name);
        } else {
            // Add file to selection if it is not already selected
            updatedFilesSelection = [...selectedSuppFiles, file];
        }
        
        // Update state
        setSelectedSuppFiles(updatedFilesSelection);
        setSelectedSuppFileNames(updatedFilesSelection.map(x => x.name));
    }




    async function updateMetrics(account) {
        try {
            setUpdatingMetrics(true);
            const data = {
                accountURL: account.url,
                accountUsername: account.username,
                accountPassword: account.password,
            }
            const response = await axios.post(`${serverURL}/updateMetrics`, data);
            console.log('response:', response.data);
            await refreshData();
            setUpdatingMetrics(false);
        } catch (error) {
            console.error('ERROR updating metrics:', error);
            setUpdatingMetrics(false);
        }
    }

  
      
    async function searchAccounts () {
        let sortedAccounts = [...accounts]; 

        if (accountSearchValue) {
            sortedAccounts = sortedAccounts.filter(account => account.name.toLowerCase().includes(accountSearchValue.toLowerCase()));
            setFilteredAccounts(sortedAccounts);
        } else {
            setFilteredAccounts(accounts);
        }
        return 
    }

    useEffect(() => {
        searchAccounts();
    }, [accountSearchValue])

    async function sortAccountsByDate () {
        let sortedAccounts = [...accounts]; 

        sortedAccounts = sortedAccounts.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setFilteredAccounts(sortedAccounts);
        return
    }

    async function sortAccountsByAlphabet () {
        let sortedAccounts = [...accounts]; 

        sortedAccounts = sortedAccounts.sort((a, b) => a.name.localeCompare(b.name));
        setFilteredAccounts(sortedAccounts);
        return
    }





    function selectAccount (data) {
        let sortedFiles = [...data?.files]; 

        sortedFiles = sortedFiles.sort((a, b) => new Date(b.modified) - new Date(a.modified));
        setSelectedAccount({...data, files: sortedFiles});
    }

      




    return ( 
        <div className='AccountsContainer'>
            <div className='AccountsLeftContainer'>

                <div className='AccountCardAddAccount' onClick={() => {setShowCreateAccount(true)}}>
                    <AddRounded style={{width: 20, color: '#666'}} />
                    <div style={{color: '#666'}}>Link Account</div>
                </div>

                <div className='AccountsLeftScrollContainer'>

                    <div className='AccountsLeftFilter'>
                        <input className='AccountsLeftFilterInput' type='text' placeholder='Search' onChange={(e) => {setAccountSearchValue(e.target.value)}} value={accountSearchValue}/>
                        <div className='AccountsLeftFilterSortByContainer' onClick={() => {sortAccountsByDate()}}> 
                            <DateRange style={{fontSize: 20}}/>
                        </div>
                        <div className='AccountsLeftFilterSortByContainer' onClick={() => {sortAccountsByAlphabet()}}> 
                            <SortByAlphaRounded style={{fontSize: 20}}/>
                        </div>
                    </div>

                    {filteredAccounts.map((x, index) => {
                        return (
                            <div className='AccountCard' key={index} style={{backgroundColor: selectedAccount.url == x.url && '#f4f4f4'}} onClick={() => {selectAccount(x)}}>
                                <div className='AccountCardURL'>{x.name}</div>
                                <div className='AccountCardSpeed'>{new Intl.NumberFormat('en-US').format(x.send_rate)}/sec </div>
                                <div className='AccountCardUsername'>{new Intl.NumberFormat('en-US').format(x.sent_today)} / {new Intl.NumberFormat('en-US').format(x.daily_quota)} ({ new Intl.NumberFormat('en-US', { style: 'percent' }).format(x.sent_today / x.daily_quota) } used)</div>
                            </div>
                        )
                    })}

                </div>

            </div>
            <div className='AccountsRightContainer'>


            <div className='AccountsRightHeader'>
                
                <div className='AccountsRightHeaderIconRow'>
                    <div className='AccountsRightHeaderIconContainer' onClick={() => {updateMetrics(selectedAccount)}}>
                        {updatingMetrics ? <CircularProgress style={{color: '#666', width: 20, height: 20}}/> : <RefreshRounded style={{width: 20, color: '#666'}} />}
                    </div>
                    <div 
                        className='AccountsRightHeaderIconContainer' 
                        onClick={() => {
                            setShowEditAccount(true); 
                            setCreateAccountBrandID(selectedAccount.brandID)
                            setCreateAccountPassword(selectedAccount.password)
                            setCreateAccountURL(selectedAccount.url)
                            setCreateAccountUsername(selectedAccount.username)

                        }}
                    >
                        <Edit style={{width: 20, color: '#666'}} />
                    </div>
                    <div className='AccountsRightHeaderIconContainer' onClick={() => {setShowDeleteAccountPopup(true)}}>
                        <DeleteForeverRounded style={{width: 20, color: '#ff0000'}} />
                    </div>
                </div>

                <span className='AccountsRightHeaderTitle'>{selectedAccount.url}</span>
                <span className='AccountsRightHeaderSubtitle'>{selectedAccount.username}</span>
                <span className='AccountsRightHeaderSubtitle'>{selectedAccount.password}</span>
                {selectedAccount.brandID && (<span className='AccountsRightHeaderSubtitle'>Brand ID: {selectedAccount.brandID}</span>)}
            </div>

                

            {selectedAccount?.files?.map((x, index) => { return (
                <React.Fragment key={index}>
                    {!(x.name)?.startsWith('.') &&
                        <div className='FileListItemRow' 
                            style={{
                                backgroundColor: selectedFile.name == x.name && '#00a76f30',
                                boxShadow: selectedFile.name == x.name && '0px 0px 10px #222',
                                border: selectedFile.name == x.name && '1px solid #00a76f'
                            }}
                        > 

                            <div className='FileListItemRowLeft'>
        
                            
                                <span className={x?.type == 'segment' ? 'FileListRowExtensionSegment' : 'FileListRowExtension'}>{(x?.type)}</span>
                                <span className='FileListRowName'>
                                    {
                                        x.name.startsWith('hidden_') ? 
                                            x.name.length > 52 ? (x.name).slice(7,52) + '...' : (x.name).slice(7,52)
                                        :
                                            x.name.length > 45 ? (x.name).slice(0,45) + '...' : (x.name).slice(0,45)
                                    }
                                </span>
                            </div>
                            <div className='FileListRowAddressesColumn'>
                                <span className='FileListRowTotalAddresses'>{new Intl.NumberFormat('en-US')?.format(x?.alreadySent)}</span>
                                <span className='FileListRowUniqueAddresses'>{new Intl.NumberFormat('en-US')?.format(x?.attributes?.totalEmailCount)}</span>
                            </div>
                            <span className='FileListRowSize'>{formatFileSize(x.size)}</span>

                            <div className='FileListRowMoreIcon' onClick={() => { setShowMenu(!showMenu) }}>
                                <MoreVertRounded style={{ width: 20, color: '#666' }}  />
                                {showMenu && (
                                    <div className='FileListRowDropdown'>
                                        <div className='FileListRowDropdownSection2'>
                                            <span className='FileListRowDropdownSection2Link' onClick={() => {setSelectedFileForAssociateWithAccount(x); setShowAssociateWithAccountPopup(true)}}>Share with another account</span>
                                            <span className='FileListRowDropdownSection2Link' onClick={() => {setShowRenamePopup(true); setSelectedFileForRename(x); setRenameText(x.name)}}>Rename</span>
                                            <span className='FileListRowDropdownSection2Link' onClick={() => {setShowSelectionPane(true)}}>See Info</span>
                                            <span className='FileListRowDropdownSection2Link' onClick={() => {downloadFile(x.name)}}>Download</span>
                                        </div>
                                        <div className='FileListRowDropdownSection3'>
                                            <span className='FileListRowDropdownSection3LogoutButton' onClick={() => {disassociateWithAccount(x.name)}}>Unshare with this account</span>
                                            <span className='FileListRowDropdownSection3LogoutButton' onClick={() => {setShowDeletePopup(true); setSelectedFileForDelete(x)}}>Delete File</span>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div 
                                className='FileListRowSendIcon' 
                                onClick={() => { 
                                    setShowPushToSendy(true); 
                                    setPushToSendyListInfo(x) 
                                    setSelectedPushToSendyPreset(0); 
                                    setListRangeStart(x.alreadySent + 1 || 0); 
                                    setListRangeEnd(
                                        ((selectedAccount.daily_quota * 1.19) + (x.alreadySent + 1)) > x?.attributes?.totalEmailCount ? 
                                        x?.attributes?.totalEmailCount 
                                            : 
                                            ((selectedAccount.daily_quota * 1.19) + (x.alreadySent + 1))
                                    );                                    
                                    setSendyListName(`${formatDate(new Date())}_${selectedAccount.name}_segment.csv`);

                                }}
                            >
                                <ArrowForwardRounded style={{ width: 20, color: '#00a76f' }}  />
                            </div>     

                        </div>
                    }
                </React.Fragment>
            )})}


            {selectedAccount?.files?.length == 0 && (
                <span className='AccountsFileListEmptyText'>Files that you associate with this account will appear here</span>
            )}


            </div>









            <div className={showCreateAccount ? 'AccountsCreateAccountBackground' : 'AccountsCreateAccountBackgroundHidden'}>
                <div className='AccountsCreateAccountContainer'>
                    <div className='AccountsCreateAccountContainerCloseIcon' onClick={() => {setShowCreateAccount(false)}}>
                        <CloseRounded style={{width: 20, color: '#666'}} />
                    </div>
                    <span className='AccountsCreateAccountPopupTitle'>Link Account</span>
                    <div className='AccountsCreateAccountInputCountainer'>
                        <span className='AccountsCreateAccountInputLabel'>URL (without www.)</span>
                        <input className='AccountsCreateAccountInput' type='text' placeholder='Enter the Sendy URL ' onChange={(e) => {setCreateAccountURL(e.target.value)}} value={createAccountURL} />
                        <span className='AccountsCreateAccountInputLabel'>Username</span>
                        <input className='AccountsCreateAccountInput' type='text' placeholder='Enter the Sendy username' onChange={(e) => {setCreateAccountUsername(e.target.value)}} value={createAccountUsername} />
                        <span className='AccountsCreateAccountInputLabel'>Password</span>
                        <input className='AccountsCreateAccountInput' type='text' placeholder='Enter the Sendy password' onChange={(e) => {setCreateAccountPassword(e.target.value)}} value={createAccountPassword} />
                        <span className='AccountsCreateAccountInputLabel'>Brand ID (usually 1)</span>
                        <input className='AccountsCreateAccountInput' type='text' placeholder='Enter the brand ID currently in use' onChange={(e) => {setCreateAccountBrandID(e.target.value)}} value={createAccountBrandID} />
                    </div>
                    <div className='AccountsCreateAccountButton' onClick={() => {createAccount()}}>Link Account</div>
                </div>
            </div>






            
            <div className={showEditAccount ? 'AccountsCreateAccountBackground' : 'AccountsCreateAccountBackgroundHidden'}>
                <div className='AccountsCreateAccountContainer'>
                    <div className='AccountsCreateAccountContainerCloseIcon' onClick={() => {setShowEditAccount(false)}}>
                        <CloseRounded style={{width: 20, color: '#666'}} />
                    </div>
                    <span className='AccountsCreateAccountPopupTitle'>Edit Account</span>
                    <div className='AccountsCreateAccountInputCountainer'>
                        <span className='AccountsCreateAccountInputLabel'>URL (without www.)</span>
                        <input className='AccountsCreateAccountInput' type='text' placeholder='Enter the Sendy URL ' onChange={(e) => {setCreateAccountURL(e.target.value)}} value={createAccountURL} />
                        <span className='AccountsCreateAccountInputLabel'>Username</span>
                        <input className='AccountsCreateAccountInput' type='text' placeholder='Enter the Sendy username' onChange={(e) => {setCreateAccountUsername(e.target.value)}} value={createAccountUsername} />
                        <span className='AccountsCreateAccountInputLabel'>Password</span>
                        <input className='AccountsCreateAccountInput' type='text' placeholder='Enter the Sendy password' onChange={(e) => {setCreateAccountPassword(e.target.value)}} value={createAccountPassword} />
                        <span className='AccountsCreateAccountInputLabel'>Brand ID (usually 1)</span>
                        <input className='AccountsCreateAccountInput' type='text' placeholder='Enter the brand ID currently in use' onChange={(e) => {setCreateAccountBrandID(e.target.value)}} value={createAccountBrandID} />
                    </div>
                    <div className='AccountsCreateAccountButton' onClick={() => {editAccount()}}>Save Changes</div>
                </div>
            </div>




            <div className={showDeleteAccountPopup ? 'AccountsRenamePopupBackground' : 'AccountsRenamePopupBackgroundHidden'}>
                <div className='AccountsRenamePopupContainer'>
                    <div className='AccountsRenamePopupContainerCloseIcon' onClick={() => {setShowDeleteAccountPopup(false)}}>
                        <CloseRounded style={{width: 20, color: '#666'}} />
                    </div>
                    <span className='AccountsRenamePopupTitle'>Delete <span className='AccountsRenamePopupTitleListName'>{selectedAccount.name}</span></span>
                        
                    <span>Are you sure you want to permanently delete {selectedAccount.name}?</span>
            
                        
                    <span  className='AccountsDeletePopupSubmitButton' onClick={() => {deleteAccount()}}>Delete</span>
                    
                </div>
            </div>




            <div className={showAssociateWithAccountPopup ? 'AccountsPushToSendyBackground' : 'AccountsPushToSendyBackgroundHidden'}>
                <div className='AccountsPushToSendyContainer'>
                    <div className='AccountsPushToSendyContainerCloseIcon' onClick={() => {setShowAssociateWithAccountPopup(false)}}>
                        <CloseRounded style={{width: 20, color: '#666'}} />
                    </div>
                    <span className='AccountsPushToSendyTitle'>Share "<b>{selectedFileForAssociateWithAccount.name}</b>" with account</span>
                    <div className='AccountsPushToSendyAccountRowContainer'>
                        {accounts.map((x, index) => { return (
                            <div key={index} className={selectedAccountForAssociateWithAccount.name == x.name ? 'AccountsPushToSendyAccountRowSelected' : 'AccountsPushToSendyAccountRow'} onClick={() => {setSelectedAccountForAssociateWithAccount(x)}}>
                                <span>{x.url}</span>
                            </div>
                        )})}
                    </div>
               
                    <span className='AccountsPushToSendySubmitButton' onClick={() => {associateWithAccount()}}>Share</span>
                    
                </div>
            </div>






            <div className={showPushToSendy ? 'AccountsPushToSendyBackground' : 'AccountsPushToSendyBackgroundHidden'}>
                <div className='AccountsPushToSendyContainer'>
                    <div className='AccountsPushToSendyContainerCloseIcon' onClick={() => {setShowPushToSendy(false)}}>
                        <CloseRounded style={{width: 20, color: '#666'}} />
                    </div>
                    <span className='AccountsPushToSendyTitle'>Push list to Sendy</span>

    
               
                    
                    <div className='AccountsPushToSendyListPresetRow'>
                        <span 
                            className={selectedPushToSendyPreset == 0 ? 'AccountsPushToSendyListPresetRowOptionSelected' : 'AccountsPushToSendyListPresetRowOption'} 
                            onClick={() => {
                                setSelectedPushToSendyPreset(0); 
                                setListRangeStart(pushToSendyListInfo.alreadySent + 1 || 0); 
                                setListRangeEnd(
                                    ((selectedAccount.daily_quota * 1.19) + (pushToSendyListInfo.alreadySent + 1)) > pushToSendyListInfo?.attributes?.totalEmailCount ? 
                                        pushToSendyListInfo?.attributes?.totalEmailCount 
                                        : 
                                        ((selectedAccount.daily_quota * 1.19) + (pushToSendyListInfo.alreadySent + 1))
                                );
                            }}
                        >19% Quota Increase</span>
                        <span 
                            className={selectedPushToSendyPreset == 1 ? 'AccountsPushToSendyListPresetRowOptionSelected' : 'AccountsPushToSendyListPresetRowOption'} 
                            onClick={() => {
                                setSelectedPushToSendyPreset(1); 
                                setListRangeStart(pushToSendyListInfo.alreadySent + 1 || 0); 
                                setListRangeEnd(
                                    ((selectedAccount.daily_quota) + (pushToSendyListInfo.alreadySent + 1)) > pushToSendyListInfo?.attributes?.totalEmailCount ? 
                                        pushToSendyListInfo?.attributes?.totalEmailCount 
                                        : 
                                        ((selectedAccount.daily_quota) + (pushToSendyListInfo.alreadySent + 1))
                                );
                            }}
                        >Current Quota</span>
                        <span 
                            className={selectedPushToSendyPreset == 2 ? 'AccountsPushToSendyListPresetRowOptionSelected' : 'AccountsPushToSendyListPresetRowOption'} 
                            onClick={() => {
                                setSelectedPushToSendyPreset(2); 
                                setListRangeStart(pushToSendyListInfo.alreadySent + 1 || 0); 
                                setListRangeEnd(0); 
                            }}
                        >Custom</span>
                        <span 
                            className={selectedPushToSendyPreset == 3 ? 'AccountsPushToSendyListPresetRowOptionSelected' : 'AccountsPushToSendyListPresetRowOption'} 
                            onClick={() => {
                                setSelectedPushToSendyPreset(3); 
                                setListRangeStart(1); 
                                setListRangeEnd(pushToSendyListInfo?.attributes?.totalEmailCount);
                            }}
                        >Entire List</span>
                    </div>


                    <div className='AccountsPushToSendyListRangeInputRow'>
                        <div className='AccountsPushToSendyListNameInputContainer'>
                            <span className='AccountsPushToSendyListNameInputLabel'>Range Start</span>
                            <input className='AccountsPushToSendyListNameInput' type='text' placeholder='Enter range start here' onChange={(e) => {setListRangeStart(e.target.value)}} value={listRangeStart} />
                        </div>
                        
                        <div className='AccountsPushToSendyListNameInputContainer'>
                            <span className='AccountsPushToSendyListNameInputLabel'>Range End</span>
                            <input className='AccountsPushToSendyListNameInput' type='text' placeholder='Enter range end here' onChange={(e) => {setListRangeEnd(e.target.value)}} value={listRangeEnd} />
                        </div>
                    </div>


                    <span className='AccountsPushToSendyListNameInputLabel'>Select Suppression Files</span>
                    <div className='AccountsPushToSendySuppContainer'>
                        {files.map((x, index) => { return (
                            <div key={index}>
                                {(x.name !== 'lost+found' && x?.attributes?.fileType === 'supp') && (
                                    <div key={index} className={selectedSuppFileNames.includes(x.name) ? 'AccountsPushToSendySuppSelected' : 'AccountsPushToSendySupp'} onClick={() => {adjustSelection(x)}}>
                                        <span>{x.name}</span>
                                    </div>
                                )}
                            </div>
                        )})}
                    </div>



                    <div className='AccountsPushToSendyListNameInputContainer'>
                        <span className='AccountsPushToSendyListNameInputLabel'>List name to appear in Sendy (add .csv)</span>
                        <input className='AccountsPushToSendyListNameInput' type='text' placeholder='Enter list name' onChange={(e) => {setSendyListName(e.target.value)}} value={sendyListName} />
                    </div>


                   
                    {pushingToSendy ?
                        <span className='AccountsPushToSendySubmitButton'>Pushing</span>
                    :
                        <span  className='AccountsPushToSendySubmitButton' onClick={() => {pushToSendy()}}>Push Now</span>
                    }
                </div>
            </div>








            <div className={showRenamePopup ? 'AccountsRenamePopupBackground' : 'AccountsRenamePopupBackgroundHidden'}>
                <div className='AccountsRenamePopupContainer'>
                    <div className='AccountsRenamePopupContainerCloseIcon' onClick={() => {setShowRenamePopup(false)}}>
                        <CloseRounded style={{width: 20, color: '#666'}} />
                    </div>
                    <span className='AccountsRenamePopupTitle'>Rename <span className='AccountsRenamePopupTitleListName'>{selectedFileForRename.name}</span></span>
                        
                        <input className='AccountsRenamePopupListNameInput' type='text' placeholder='File Name' onChange={(e) => {setRenameText(e.target.value)}} value={renameText} />

            
                        
                    <span  className='AccountsRenamePopupSubmitButton' onClick={() => {renameFile()}}>Rename</span>
                    
                </div>
            </div>





            <div className={showDeletePopup ? 'AccountsRenamePopupBackground' : 'AccountsRenamePopupBackgroundHidden'}>
                <div className='AccountsRenamePopupContainer'>
                    <div className='AccountsRenamePopupContainerCloseIcon' onClick={() => {setShowDeletePopup(false)}}>
                        <CloseRounded style={{width: 20, color: '#666'}} />
                    </div>
                    <span className='AccountsRenamePopupTitle'>Delete <span className='AccountsRenamePopupTitleListName'>{selectedFileForDelete.name}</span></span>
                        
                    <span style={{paddingLeft: 20, paddingRight: 20}}>Are you sure you want to permanently delete {selectedFileForDelete.name}?</span>
            
                        
                    <span  className='AccountsDeletePopupSubmitButton' onClick={() => {deleteFile(selectedFileForDelete)}}>Delete</span>
                    
                </div>
            </div>
            



        </div>
    );
}

export default Accounts;