

import React, {useState, useEffect} from 'react';
import './FileList.css';
import { CheckRounded, Close, CloseRounded, DeleteForeverRounded, DeleteRounded, DownloadRounded, MenuRounded, More, MoreVertRounded, North, South } from '@mui/icons-material';
import axios from 'axios';
import { Menu } from '@mui/material';
import { collection, doc, getDoc, setDoc, onSnapshot, query, arrayUnion, updateDoc } from 'firebase/firestore';
import {db} from '../FirebaseInit';



function FileList({serverURL, selectedFiles, setSelectedFiles, refreshFiles, setRefreshFiles, selectedFileNames, setSelectedFileNames, showSelectionPane, setShowSelectionPane, darkMode}) {
    const [files, setFiles] = useState([]);
    const [showMenu, setShowMenu] = useState(false);
    const [showPushToSendy, setShowPushToSendy] = useState(false);
    const [pushToSendyListInfo, setPushToSendyListInfo] = useState({});
    const [accounts, setAccounts] = useState([]);
    const [sendyListName, setSendyListName] = useState(''); 
    const [selectedAccount, setSelectedAccount] = useState({});
    const [pushingToSendy, setPushingToSendy] = useState(false); 
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [selectedFileForDelete, setSelectedFileForDelete] = useState({});
    const [showRenamePopup, setShowRenamePopup] = useState(false);
    const [selectedFileForRename, setSelectedFileForRename] = useState({});
    const [renameText, setRenameText] = useState('');
    const [showAssociateWithAccountPopup, setShowAssociateWithAccountPopup] = useState(false);
    const [selectedFileForAssociateWithAccount, setSelectedFileForAssociateWithAccount] = useState({});
    const [selectedAccountForAssociateWithAccount, setSelectedAccountForAssociateWithAccount] = useState({});
    const [showFileType, setShowFileType] = useState('all');
    const [showTypeFilter, setShowTypeFilter] = useState(true)
    const [showSuppressionPopup, setShowSuppressionPopup] = useState(false);
    const [sortByAttribute, setSortByAttribute] = useState('date');
    const [sortOrder, setSortOrder] = useState('asc');
    const [sampleLoading, setSampleLoading] = useState(false);


    async function getFiles() {
        try {
          const response = await axios.get(serverURL + '/getFiles');
          console.log('response:', response.data);
          setFiles(response.data);
        } catch (error) {
          console.error('ERROR getting files:', error);
        }
    }

    useEffect(() => {
        getFiles();
    }, [refreshFiles])



    function adjustSelection(file) {
        // Convert selectedFileNames to a Set for faster lookup
        const selectedFileNamesSet = new Set(selectedFileNames);
        
        let updatedFilesSelection;
        
        // Check if the file name is in selectedFileNames
        if (selectedFileNamesSet.has(file.name)) {
            // Remove file from selection if it is already selected
            updatedFilesSelection = selectedFiles.filter(item => item.name !== file.name);
        } else {
            // Add file to selection if it is not already selected
            updatedFilesSelection = [...selectedFiles, file];
        }
        
        // Update state
        setSelectedFiles(updatedFilesSelection);
        setSelectedFileNames(updatedFilesSelection.map(x => x.name));
    }
    


    function formatFileSize(bytes) {
        if (bytes === 0) return '0 Bytes';
    
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        const formattedSize = (bytes / Math.pow(1024, i)).toFixed(1);
        return formattedSize + ' ' + sizes[i];
    }

    const downloadFile = async (filename) => {
        try {
          const response = await axios.get(`${serverURL}/downloadFile?fileName=${filename}`, {
            responseType: 'blob', // Set responseType to 'blob' for binary data
          });
      
          // Create a URL for the blob
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename); // Specify the file name for the download
          document.body.appendChild(link);
          link.click();
          link.remove();
          window.URL.revokeObjectURL(url); // Clean up the URL object
        } catch (error) {
          console.error('Error downloading the file:', error);
        }
    };



    async function deleteFile(filename) {
        try {
            const response = await axios.delete(serverURL + `/deleteFile?fileName=${filename}`);
            console.log('response:', response.data);
            setRefreshFiles(!refreshFiles);
            setShowDeletePopup(false);
        } catch (error) {
            console.error('ERROR deleting file:', error);
        }
    }



    async function pushToSendy() {
        try {
            setPushingToSendy(true)
            const listInfo = {
                accountURL: selectedAccount.url,
                accountUsername: selectedAccount.username,
                accountPassword: selectedAccount.password,
                listName: sendyListName,
                listDirectory: pushToSendyListInfo.name,
            }

            console.log('listInfo:', listInfo);

            const response = await axios.post('${serverURL}/createList', listInfo);
            console.log('response:', response.data);
            setRefreshFiles(!refreshFiles);
            setPushingToSendy(false);
        } catch (error) {
            console.error('ERROR pushing file:', error);
            setPushingToSendy(false);
        }
    }



    async function getAccounts() {
        const q = query(collection(db, "accounts"));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const accountsArray = [];
            querySnapshot.forEach((doc) => {
                accountsArray.push({
                    id: doc.id,
                    ...doc.data()
                });
            });
            setAccounts(accountsArray);
        });
    }

    useEffect(() => {
        if (showPushToSendy || showAssociateWithAccountPopup) {
            getAccounts();
        }
    }, [showPushToSendy, showAssociateWithAccountPopup])





    async function renameFile() {
        try {
            const data = {
                oldName: selectedFileForRename.name,
                newName: renameText
            }
            const response = await axios.post(serverURL + `/renameFile`, data);
            console.log('response:', response.data);
            setRefreshFiles(!refreshFiles);
            setShowRenamePopup(false);
        } catch (error) {
            console.error('ERROR renaming file:', error);
        }
    }



    async function shuffleData () {
        setSampleLoading(true);
        const requestData = {
            "csvFilePath": selectedFileForAssociateWithAccount.name,
            "outputFilePath": 'hidden_' + selectedAccountForAssociateWithAccount.name + '_' + selectedFileForAssociateWithAccount.name,
            "listRangeStart": 0,
            "listRangeEnd": 100000000000,
            "shuffle": true,
            "totalEmailCount": selectedFileForAssociateWithAccount.attributes.totalEmailCount
        }
      
        console.log('requestData:', requestData);
    
    
        try {
        const response = await axios.post(`${serverURL}/sample`, requestData);
        console.log('Response:', response.data);
        setSampleLoading(false);
        //setRefreshFiles(!refreshFiles);
        } catch (error) {
        console.error('There was an error!', error);
        setSampleLoading(false);
        }
    }
    




    async function associateWithAccount() {
        const accountRef = doc(db, "accounts", selectedAccountForAssociateWithAccount.id);
    
        // Get the current document data
        const accountDoc = await getDoc(accountRef);
        if (!accountDoc.exists()) {
            console.error("No such document!");
            return;
        }
    
        const accountData = accountDoc.data();
        const files = accountData.files || [];
    
        // Check if a file with the same name already exists
        const fileExists = files.some(file => file.name === selectedFileForAssociateWithAccount.name);
    
        if (!fileExists) {

            await shuffleData();
            // File does not exist, update the document
            await updateDoc(accountRef, {
                files: arrayUnion({
                    name: 'hidden_' + selectedAccountForAssociateWithAccount.name + '_' + selectedFileForAssociateWithAccount.name,
                    created: new Date(),
                    modified: new Date(),
                    alreadySent: 0,
                    records: 0,
                    type: "master"
                })
            });
        } else {
            console.log("File already exists in the array.");
        }
        setShowAssociateWithAccountPopup(false);
    }





    

    useEffect(() => {
        let sortedFiles = [...files]; // Create a copy of the files array
    
        // Sort files by date
        if (sortByAttribute === 'date' && sortOrder === 'asc') {
            sortedFiles.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        }
        if (sortByAttribute === 'date' && sortOrder === 'desc') {
            sortedFiles.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        }
        // Sort files by name
        if (sortByAttribute === 'name' && sortOrder === 'asc') {
            sortedFiles.sort((a, b) => a.name.localeCompare(b.name));
        }
        if (sortByAttribute === 'name' && sortOrder === 'desc') {
            sortedFiles.sort((a, b) => b.name.localeCompare(a.name));
        }
    
        setFiles(sortedFiles); // Set the sorted files
    }, [sortByAttribute, sortOrder]);
    
    



    


    return ( 
        <div className='FileListContainer'>
            {/* <span className='FileListDate'>Tuesday August 3, 2024</span> */}

            {showTypeFilter &&
                <div className='FileListFilterRowContainer'>
                    <div className='FileListFilterRowLeft' style={{backgroundColor: !darkMode && '#fff'}}>
                        <span className={showFileType === 'all' ? 'FileListFilterLeftButtonSelected' : 'FileListFilterLeftButton'} onClick={() => {setShowFileType('all')}}>All</span>
                        <span className={showFileType === 'master' ? 'FileListFilterLeftButtonSelected' : 'FileListFilterLeftButton'} onClick={() => {setShowFileType('master')}}>Master</span>
                        <span className={showFileType === 'supp' ? 'FileListFilterLeftButtonSelected' : 'FileListFilterLeftButton'} onClick={() => {setShowFileType('supp')}}>Suppression</span>
                    </div>
                    <div className='FileListFilterRowRight' style={{backgroundColor: !darkMode && '#fff'}}>
                        <span className={sortByAttribute === 'date' ? 'FileListFilterButtonRightSelected' : 'FileListFilterRightButton'} onClick={
                            () => {setSortByAttribute('date');
                                if (sortOrder === 'asc') {
                                    setSortOrder('desc');
                                }
                                if (sortOrder === 'desc') {
                                    setSortOrder('asc');
                                }
                            }}>
                            Date 
                            {sortByAttribute === 'date' && sortOrder === 'asc' && 
                                <South style={{fontSize: 13, marginLeft: 5, marginBottom: 2}}/>
                            } 
                            {sortByAttribute === 'date' && sortOrder === 'desc' && 
                                <North style={{fontSize: 13, marginLeft: 5, marginBottom: 2}}/>
                            } 
                        </span>
                        <span className={sortByAttribute === 'name' ? 'FileListFilterButtonRightSelected' : 'FileListFilterRightButton'} onClick={
                            () => {setSortByAttribute('name');
                                if (sortOrder === 'asc') {
                                    setSortOrder('desc');
                                }
                                if (sortOrder === 'desc') {
                                    setSortOrder('asc');
                                }
                            }}>
                            Name 
                            {sortByAttribute === 'name' && sortOrder === 'asc' && 
                                <South style={{fontSize: 13, marginLeft: 5, marginBottom: 2}}/>
                            } 
                            {sortByAttribute === 'name' && sortOrder === 'desc' && 
                                <North style={{fontSize: 13, marginLeft: 5, marginBottom: 2}}/>
                            } 
                        </span>                    
                    </div>
                </div>
            }

            {files.map((x, index) => { return (
                <React.Fragment key={index}>
                    {
                        !(x.name)?.startsWith('.') && 
                        !(x.name).startsWith('hidden_') &&
                        x?.isDirectory == false && 
                        !(x.name).includes('_segment') && 
                        (showFileType === 'all' || (showFileType == x.attributes.fileType)) &&

                        <div className='FileListItemRow' 
                            style={{
                                backgroundColor: selectedFileNames.includes(x.name) && '#00a76f20',
                                boxShadow: selectedFileNames.includes(x.name) && '0px 0px 20px #00a76f10',
                                border: selectedFileNames.includes(x.name) && '1px solid #00a76f00'
                            }}
                        > 

                            <div className='FileListItemRowLeft'>
                                {selectedFileNames.includes(x.name) ?
                                    <div className='FileListRowCheckboxContainer' onClick={() => { adjustSelection(x) }}>
                                        <div className='FileListRowCheckboxChecked'>
                                            <CheckRounded style={{ width: 15 }} />
                                        </div>
                                    </div>
                                    :
                                    <div className='FileListRowCheckboxContainer' onClick={() => { adjustSelection(x) }}>
                                        <div className='FileListRowCheckbox'>
                                            <CheckRounded style={{ width: 15 }} />
                                        </div>
                                    </div>
                                }
                            
                                <span className='FileListRowExtension'>{(x.name)?.split('.')?.pop()}</span>
                                <span className='FileListRowName' style={{color: !darkMode && '#666'}}>{(x.name)?.split('.')?.slice(0, -1)?.join('.')}</span>
                            </div>
                            <div className='FileListRowAddressesColumn'>
                                <span className='FileListRowTotalAddresses'>{new Intl.NumberFormat('en-US')?.format(x.attributes?.totalEmailCount)}</span>
                                <span className='FileListRowUniqueAddresses'>{new Intl.NumberFormat('en-US')?.format(x.attributes?.uniqueEmails)}</span>
                            </div>
                            <span className='FileListRowSize' style={{color: !darkMode && '#666'}}>{formatFileSize(x.size)}</span>
                            {/* <div className='FileListRowDownloadIcon' onClick={() => { downloadFile(x.name) }}>
                                <DownloadRounded style={{ width: 20, color: '#666' }} />
                            </div>    */}
                            <div className='FileListRowMoreIcon' onClick={() => { setShowMenu(!showMenu) }}>
                                <MoreVertRounded style={{ width: 20, color: !darkMode && '#666' }}  />
                                {showMenu && (
                                    <div className='FileListRowDropdown'>
                                        <div className='FileListRowDropdownSection2'>
                                            <span className='FileListRowDropdownSection2Link' onClick={() => {setSelectedFileForAssociateWithAccount(x); setShowAssociateWithAccountPopup(true)}}>Share with account</span>
                                            <span className='FileListRowDropdownSection2Link' onClick={() => {setShowSuppressionPopup(true)}}>Suppress</span>
                                            {/* <span className='FileListRowDropdownSection2Link' onClick={() => {setShowPushToSendy(true); setPushToSendyListInfo(x)}}>Push to Sendy</span> */}
                                            <span className='FileListRowDropdownSection2Link' onClick={() => {setShowRenamePopup(true); setSelectedFileForRename(x); setRenameText(x.name)}}>Rename</span>
                                            <span className='FileListRowDropdownSection2Link' onClick={() => {setShowSelectionPane(true); setSelectedFileNames([x.name]); setSelectedFiles([x])}}>See Info</span>
                                            <span className='FileListRowDropdownSection2Link' onClick={() => {downloadFile(x.name)}}>Download</span>
                                        </div>
                                        <div className='FileListRowDropdownSection3'>
                                            <span className='FileListRowDropdownSection3LogoutButton' onClick={() => {setShowDeletePopup(true); setSelectedFileForDelete(x)}}>Delete File</span>
                                        </div>
                                    </div>
                                )}
                            </div>   
                        </div>
                    }
                </React.Fragment>
            )})}













            <div className={showPushToSendy ? 'FileListPushToSendyBackground' : 'FileListPushToSendyBackgroundHidden'}>
                <div className='FileListPushToSendyContainer'>
                    <div className='FileListPushToSendyContainerCloseIcon' onClick={() => {setShowPushToSendy(false)}}>
                        <CloseRounded style={{width: 20, color: '#666'}} />
                    </div>
                    <span className='FileListPushToSendyTitle'>Push <span className='FileListPushToSendyTitleListName'>{pushToSendyListInfo.name}</span> to Sendy</span>
                    <div>
                        <input className='FileListPushToSendyListNameInput' type='text' placeholder='List name to appear in Sendy' onChange={(e) => {setSendyListName(e.target.value)}} value={sendyListName} />
                        <div>
                            {accounts.map((x, index) => { return (
                                <div key={index} className={selectedAccount.name == x.name ? 'FileListPushToSendyAccountRowSelected' : 'FileListPushToSendyAccountRow'} onClick={() => {setSelectedAccount(x)}}>
                                    <span>{x.url}</span>
                                </div>
                            )})}
                        </div>
                    </div>
               
                    {pushingToSendy ?
                        <span onClick={() => {pushToSendy()}}>Pushing</span>
                    :
                        <span onClick={() => {pushToSendy()}}>Push Now</span>
                    }
                </div>
            </div>



















            <div className={showAssociateWithAccountPopup ? 'AccountsPushToSendyBackground' : 'AccountsPushToSendyBackgroundHidden'}>
                <div className='AccountsPushToSendyContainer'>
                    <div className='AccountsPushToSendyContainerCloseIcon' onClick={() => {setShowAssociateWithAccountPopup(false)}}>
                        <CloseRounded style={{width: 20, color: '#666'}} />
                    </div>
                    <span className='AccountsPushToSendyTitle'>Share "<b>{selectedFileForAssociateWithAccount.name}</b>" with account</span>
                    <div className='AccountsPushToSendyAccountRowContainer'>
                        {accounts.map((x, index) => { return (
                            <div key={index} className={selectedAccountForAssociateWithAccount.name == x.name ? 'AccountsPushToSendyAccountRowSelected' : 'AccountsPushToSendyAccountRow'} onClick={() => {setSelectedAccountForAssociateWithAccount(x)}}>
                                <span>{x.name}</span>
                            </div>
                        )})}
                    </div>
               
                    <span className='AccountsPushToSendySubmitButton' onClick={() => {associateWithAccount()}}>{sampleLoading ? "Shuffling list" : "Share"}</span>
                    
                </div>
            </div>









            <div className={showRenamePopup ? 'AccountsRenamePopupBackground' : 'AccountsRenamePopupBackgroundHidden'}>
                <div className='AccountsRenamePopupContainer'>
                    <div className='AccountsRenamePopupContainerCloseIcon' onClick={() => {setShowRenamePopup(false)}}>
                        <CloseRounded style={{width: 20, color: '#666'}} />
                    </div>
                    <span className='AccountsRenamePopupTitle'>Rename <span className='AccountsRenamePopupTitleListName'>{selectedFileForRename.name}</span></span>
                        
                        <input className='AccountsRenamePopupListNameInput' type='text' placeholder='File Name' onChange={(e) => {setRenameText(e.target.value)}} value={renameText} />

            
                        
                    <span  className='AccountsRenamePopupSubmitButton' onClick={() => {renameFile()}}>Rename</span>
                    
                </div>
            </div>





            <div className={showDeletePopup ? 'AccountsRenamePopupBackground' : 'AccountsRenamePopupBackgroundHidden'}>
                <div className='AccountsRenamePopupContainer'>
                    <div className='AccountsRenamePopupContainerCloseIcon' onClick={() => {setShowDeletePopup(false)}}>
                        <CloseRounded style={{width: 20, color: '#666'}} />
                    </div>
                    <span className='AccountsRenamePopupTitle'>Delete <span className='AccountsRenamePopupTitleListName'>{selectedFileForDelete.name}</span></span>
                        
                    <span style={{padding: 20, color: '#444', display: 'block', textAlign: 'center'}}>Are you sure you want to permanently delete {selectedFileForDelete.name}?</span>
            
                        
                    <span  className='AccountsDeletePopupSubmitButton' onClick={() => {deleteFile(selectedFileForDelete.name)}}>Delete</span>
                    
                </div>
            </div>





        </div>
    );
}

export default FileList;